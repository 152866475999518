import { navigate } from 'gatsby';
import { ANALYTICS_CONSTANTS, THANKYOU_PAGE_PATH } from "../src/constants";
import { knowTheApkOrigin, knowTheApkOriginForThankyou, knowTheApkOriginOnLoad } from "./downloadLinkUtils";

// for api from APK
export function apkPromiseFunction(apkPromise, target) {
  apkPromise.then((res) => {
    if (res) {
      window.open(res.apkLink, target);
    } else {
      window.open(process.env.DEFAULT_APK_LINK, target);
    }
  })
}

// for smartscript fb link
export function smartScriptFbLinkFunction(target) {
 if(target) {
      window.open(target, '_self');
    } else {
      window.open(process.env.DEFAULT_APK_LINK, '_self');
    }
}

// for redirect to thankyou page
export function redirectToThankyou(pageRelatedTo) {
  navigate(`${THANKYOU_PAGE_PATH}?page=${pageRelatedTo}`);
}

// for redirect to video section
export function redirectToVideoSectionInMobile() {
  // window.scrollTo(0, scollY);
  let videoSection = document.getElementsByClassName('video-section-container');
  for (let i = 0; i < videoSection.length; i++) {
    window.scrollTo(0, videoSection[i].getBoundingClientRect().top + window.scrollY - 30);
  }
}
//for ls10 blankwhite screen
export function whiteScreenLStenClick() {
  if (!document.getElementById('white-screen-overlay')) {
    const overlay = document.createElement('div');
    overlay.id = 'white-screen-overlay';
    overlay.className = 'white-screen-overlay';

    const text = document.createElement('div');
    text.className = 'white-screen-text';
    text.innerText = 'Please check notifications';

    const image = document.createElement('img');
    image.src = 'https://assets.zupee.com/downloads/assets/notifications-ls10-page.webp';
    image.alt = 'notification image';
    image.className = 'white-screen-image';

    const bottomContainer = document.createElement('div');
    bottomContainer.className = 'bottom-container';

    const safeText = document.createElement('div');
    safeText.className = 'safe-text';
    const safeImage = document.createElement('img');
    safeImage.src = 'https://assets.zupee.com/downloads/assets/Frame-ls10.png'; 
    safeImage.alt = 'safe image';
    safeImage.className = 'safe-image';
    const safeTextContent = document.createElement('span');
    safeTextContent.innerText = 'Our app is 100% safe';
    safeText.appendChild(safeImage);
    safeText.appendChild(safeTextContent);

    const downloadText = document.createElement('div');
    downloadText.className = 'download-text';
    downloadText.innerHTML = `Download didn't start? <a class="restart-download" href="https://apk.zupee.com/zupee/default/install-zupee.apk">Restart the Download</a>`;

    bottomContainer.appendChild(safeText);
    bottomContainer.appendChild(downloadText);

    overlay.appendChild(text);
    overlay.appendChild(image);
    overlay.appendChild(bottomContainer);
    document.body.appendChild(overlay);
    overlay.offsetHeight;
    overlay.classList.add('show');

    setTimeout(() => {
      overlay.classList.remove('show');
      overlay.addEventListener('transitionend', () => {
        document.body.removeChild(overlay);
      }, { once: true });
    }, 30000); 
  }
}


// for redirect to video section for desktop
export function redirectToVideoSectionInDesktop() {
  let elem = document.getElementsByClassName('video-section-container');
  for (let i = 0; i < elem.length; i++) {
    elem[i].scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
}

// for adding active call in sticky notification banner
export function stickyNotificationActiveFunction() {
  let element = document.getElementsByClassName('sticky-download-notification-container');
  for (let i = 0; i < element.length; i++) {
    element[i].classList.add('active-banner');
  }
}

// common for all page - redirect to thankyou page
export function redirectCheck(apkPromise, pageRelatedTo) {
  apkPromiseFunction(apkPromise, "_blank");
  redirectToThankyou(pageRelatedTo);
}

// for same page sticky notification
export function redirectCheckNotifiation(apkPromise) {
  stickyNotificationActiveFunction();
  apkPromiseFunction(apkPromise, "_self");
}

// for same page sticky notification smartscript
export function redirectCheckNotifiationForSmartScript(linkTarget) {
  stickyNotificationActiveFunction();
  smartScriptFbLinkFunction(linkTarget);
}


// for MI device
export function isMiMarketSchemeSupported() {
  try {
    //initiateLoader
    const newWindow = window.open(process.env.MI_PLAY_STORE, '_blank');
  
      if (newWindow && newWindow.location.origin === "null") {
        newWindow.close();
        return false;
      }
      return true;


  } catch (error) {
    console.error('Error occurred while attempting to open mimarket scheme:', error);
    return false;
  }
}

export function loaderActivate () {
  let element = document.getElementsByClassName('loader');
  for (let i = 0; i < element.length; i++) {
    element[i].classList.add('enable');
  }
}

export function loaderDeactivate () {
  let element = document.getElementsByClassName('loader');
  for (let i = 0; i < element.length; i++) {
    element[i].classList.remove('enable');
    element[i].classList.add('disable');
  }
}

export function onButtonClickForMIDevice() {
  if (isMiMarketSchemeSupported()) {
    console.log('Mi Market scheme is supported on this device.');
  } else {
    console.log('Mi Market scheme is not supported on this device.');
    //loader start
    loaderActivate ();
    setTimeout(()=>{
      // loader end
      loaderDeactivate();
      window.open(process.env.DEFAULT_APK_LINK, "_self");
      redirectToVideoSectionInMobile();
      stickyNotificationActiveFunction();
    }, 1000);
  }
}

// for GCLID event
export function redirectToGCLID(apkPromise, gclidValue) {
  apkPromise.then((res) => {
    if (gclidValue) {
      window.open(`https://app.adjust.net.in/774g88t?&engagement_type=fallback_click&gclid=${gclidValue}&redirect=${res.apkLink}`);
    } else {
      window.open(process.env.DEFAULT_APK_LINK, "_self");
    }
  })
  stickyNotificationActiveFunction();
}

// for GA extra event- usecase for onClick 
export function extraEventForGA(analyticsPrefix, pageRelatedTo, gatsbyDownloadBtnEvent) {
  let dataLayer = window.dataLayer || [];
  dataLayer.push({
    event: ANALYTICS_CONSTANTS.EVENT,
    category:
      analyticsPrefix +
      ANALYTICS_CONSTANTS.APK_DOWNLOAD_EVENT.CATEGORY +
      ` ${pageRelatedTo} + ${gatsbyDownloadBtnEvent}`,
    action: analyticsPrefix + ANALYTICS_CONSTANTS.APK_DOWNLOAD_EVENT.ACTION,
    label: analyticsPrefix + ANALYTICS_CONSTANTS.APK_DOWNLOAD_EVENT.LABEL
  });

  // for unique user & number of session count
  let uniqueUser = localStorage.getItem('newUUID');
  let noOfSession = sessionStorage.getItem('sessionstore');
  let labelAction = 'onClick';
  let timestamp = new Date().valueOf();
  const pageUrl = window.location.href;

  knowTheApkOrigin(uniqueUser, noOfSession, labelAction, timestamp, pageUrl);
}


export function gaOnLoad() {
  // let varForUniqueUser = localStorage.getItem('uucid');
  // if (varForUniqueUser === '' || varForUniqueUser === null) {
  //   const randomNo = Math.round(Math.random() * 10000000000000000);
  //   localStorage.setItem('uucid', randomNo.toString());
  // }

  let sessionstore = sessionStorage.getItem('sessionstore');
  if (+sessionstore < 1) {
    sessionStorage.setItem('sessionstore', sessionstore + 1);
  } else {
    let tempSession = +sessionstore + +1;
    sessionStorage.setItem('sessionstore', tempSession.toString());
  }

  let uniqueUser = localStorage.getItem('newUUID');
  let noOfSession = sessionStorage.getItem('sessionstore');
  let labelAction = 'onLoad';
  let timestamp = new Date().valueOf();
  const urlParams = new URLSearchParams(window.location.search);
  let gpsadid = urlParams.get('gps_adid');
  const pageUrl = window.location.href;

  const lsVar = localStorage.getItem("ls_gps_adid");
  if (lsVar === '' || lsVar === null) {
    localStorage.setItem("ls_gps_adid", gpsadid);
  }

  const gpsadidBlank = 'notAvailable';
  const fbclidBlank = 'notAvailable';

  let fbParams = urlParams.get('fbclid');
  if (fbParams === '' || fbParams === null) {
    fbParams = fbclidBlank;
  }

  if (gpsadid) {
    knowTheApkOriginOnLoad(uniqueUser, noOfSession, labelAction, timestamp, gpsadid, fbParams, pageUrl);
  } else {
    knowTheApkOriginOnLoad(uniqueUser, noOfSession, labelAction, timestamp, gpsadidBlank, fbParams, pageUrl);
  }

}

// tracking On Thankyou Page
export function trackingOnThankyouPage() {
  let uniqueUser = localStorage.getItem('newUUID');
  let noOfSession = sessionStorage.getItem('sessionstore');
  let labelAction = 'onClick';
  let timestamp = new Date().valueOf();
  const gpsadid = localStorage.getItem('ls_gps_adid');
  const gpsadidBlank = 'notAvailable';

  let redirectedFrom = localStorage.getItem('pageSlug');

  if (gpsadid) {
    knowTheApkOriginForThankyou(uniqueUser, noOfSession, labelAction, timestamp, redirectedFrom, gpsadid);
  } else {
    knowTheApkOriginForThankyou(uniqueUser, noOfSession, labelAction, timestamp,redirectedFrom, gpsadidBlank);
  }
}