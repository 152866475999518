import React, { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import SEOComponent from '../fields/seoField';
// import Footer from '../sections/footer';
// import Header from '../sections/header';
import StickyBottomBar from '../sections/stickyDownloadButton';
import { SEOProps } from '../../types/types';
import useQuery, { gtmInitializationScript, gtmFourInitializationScript } from './useQuery';
import PageContext from '../../context/page/pageContext';
import AnalyticsProvider from '../../context/analytics/AnalyticsProvider';
import HeaderM5 from '../sections/headerM5';
import FooterM5 from '../sections/footerM5';
import { useMediaQuery } from '../../../utils/useMedia';
import { PixelPageViewScript, PixelPurchaseScript } from './pixelScript';
import { appendScript } from '../../../utils/appendScript';
import { removeScript } from '../../../utils/removeScript';
import StickyDownloadNotificationComponent from '../sections/stickyDownloadNotification';
import Popupvideo from '../../components/sections/popupvideooverlay/Popupvideo';

import { ANALYTICS_CONSTANTS, HOTSTAR_PAGE_PATH, HOTSTAR_THANKYOU_PAGE_PATH, THANKYOU_PAGE_PATH } from '../../constants/index';
import StickyBottomBarV2 from '../sections/stickyDownloadButtonV2';
import { v4 as uuidv4 } from "uuid";


interface CommonRendererProps extends SEOProps {
	apkUpdateLink?: any;
	children: any;
	pageRelatedToProps: any;
	showPageStickyBottom: string;
	tncTitle: string;
	tncDescription: string;
	headerLinksShow: any;
	userBucket?: string;
}
const CommonPageRenderer = (props: CommonRendererProps) => {
	const isMobile = useMediaQuery('(max-width: 767px)');
	const { fbEventName, pageName, pageRelatedToProps, showPageStickyBottom, tncTitle, tncDescription, headerLinksShow } = props;
	const { data, page, popupShowRef, setPopupShowRef } = useContext(PageContext);
	const seoFields = data?.allWpPage?.edges?.[0]?.node?.[page]?.[`${page}Seofields`];
	const { gtmId, gtmFourId, analyticsPrefix } = useQuery();

	useEffect(() => {
		let varForUniqueUserNew = localStorage.getItem('newUUID');
		if (varForUniqueUserNew === '' || varForUniqueUserNew === null) {
			localStorage.setItem('newUUID', uuidv4());
		}
		let getPageSlug = localStorage.getItem('pageSlug');
		let pageSlug = window.location.pathname;

		if (getPageSlug === '' || getPageSlug === null) {
			localStorage.setItem('pageSlug', pageSlug);
		} else if (getPageSlug && !pageSlug.includes('/thankyou')) {
			localStorage.setItem('pageSlug', pageSlug);
		}
	}, []);

	useEffect(() => {
		let dataLayer = window.dataLayer || [];
		dataLayer.push({
			event: ANALYTICS_CONSTANTS.EVENT,
			category: analyticsPrefix + ANALYTICS_CONSTANTS.PAGE_LOAD_EVENT.CATEGORY + ` ${pageRelatedToProps}`,
			action: analyticsPrefix + ANALYTICS_CONSTANTS.PAGE_LOAD_EVENT.ACTION + ` ${pageName}`,
			label: analyticsPrefix + ANALYTICS_CONSTANTS.PAGE_LOAD_EVENT.LABEL
		});
	}, []);

	useEffect(() => {
		{
			pageRelatedToProps == HOTSTAR_PAGE_PATH && appendScript(PixelPageViewScript(), 'PixelPageViewScript');
			removeScript('PixelPurchaseScript');
		}
		if (THANKYOU_PAGE_PATH + location.search == HOTSTAR_THANKYOU_PAGE_PATH) {
			appendScript(PixelPurchaseScript(), 'PixelPurchaseScript');
			removeScript('PixelPageViewScript');
		}
	}, []);


	const onClosePopup = () => {
		setPopupShowRef(!popupShowRef);
	}

	const pagesWithoutGATags = ['ls9page', 'ls10page', 'ls11page'];
	return (
		<AnalyticsProvider data={analyticsPrefix}>
			<React.Fragment>
				<noscript>
					{(pageName === 'ls9page' || pageName === 'ls10page' || pageName === 'ls11page' || pageName === 'ls13page') ?
						""
						:
						<>
							<iframe
								src={`https://www.googletagmanager.com/ns.html?id=${gtmId}`}
								height='0'
								width='0'
								className='gtm-iframe'></iframe>
							<iframe
								src={`https://www.googletagmanager.com/ns.html?id=${gtmFourId}`}
								height='0'
								width='0'
								className='gtm-iframe'></iframe>
						</>
					}
				</noscript>

				{(pageName === 'lsfbpage' || pageName === 'homepage' || pageName === 'kannadanols' || pageName === 'bengalinols' || pageName === 'homepagehindi' || pageName === 'lsbhojpuripage' || pageName === 'lsgujaratipage' || pageName === 'playstore' || pageName === 'lstamilpage' || pageName === 'lskannadapage' || pageName === 'playstorehindi' || pageName === 'ls14page' || pageName === 'ls14fbmalayalam' || pageName === 'ls14fbtamilpage' || pageName === 'ls14fbkannadapage' || pageName === 'ls14fbpunjabipage' || pageName === 'ls14fboriyapage' || pageName === 'ls14fbmarathipage' || pageName === 'ls14fbgujaratipage' || pageName === 'ls14oriyapage' || pageName === 'ls14malayalampage' || pageName === 'ls14tamilpage' || pageName === 'ls3page' || pageName === 'ls5page' || pageName === 'ls1page') && <>
					<noscript><img height="1" width="1" style={{ display: "none" }} src="https://www.facebook.com/tr?id=1271831939931284&ev=PageView&noscript=1" /></noscript>
					<noscript><img height="1" width="1" style={{ display: "none" }} src="https://www.facebook.com/tr?id=1271831939931284&ev=Lead&noscript=1" /></noscript>
				</>
				}
				{(pageName === 'ls9page' || pageName === 'ls10page' || pageName === 'ls11page' || pageName === 'ls13page') ?
					<>
						{console.log(`Current page: ${pageName}`)}

					</>
					:
					<>
						{console.log(`Current with else helmet page: ${pageName}`)}
						<Helmet>
							<script async>{gtmInitializationScript(gtmId)}</script>
							<script async>{gtmFourInitializationScript(gtmFourId)}</script>
						</Helmet>
					</>
				}
				<SEOComponent
					description={seoFields?.description}
					keyWords={seoFields?.keywords}
					robots={seoFields?.robots}
					title={seoFields?.title}
					canonical={seoFields?.canonicaltag}
					// favIcon={seoFields?.favicon?.localFile?.url}
					structuredSchemaData={seoFields?.structuredschemadata}
				/>
				<HeaderM5 pageName={pageName} headerLinksShow={headerLinksShow} apkUpdateLink={props?.apkUpdateLink} />
				{props.children}

				{pageName !== 'lsgoogle' && pageName !== 'playstore' && <FooterM5 />}

				{/* for page in which t&c and description text in sticky download component */}
				{pageName !== 'thankyou' && showPageStickyBottom == 'true' ? (
					<StickyBottomBar
						showPageStickyBottom={showPageStickyBottom}
						tncTitle={tncTitle}
						tncDescription={tncDescription}
					/>
				) : null}

				{/* for rest all pages */}
				{pageName !== 'thankyou' && showPageStickyBottom !== 'true' ? <StickyBottomBar /> : null}

				{/* for ls page only StickyBottomBarV2 component */}
				{/* {pageName !== 'thankyou' && showPageStickyBottom !== 'true' && pageName === 'lspage' ? <StickyBottomBarV2 /> : null} */}

				{isMobile && <StickyDownloadNotificationComponent />}
				{popupShowRef === true && pageRelatedToProps === "ls15page" && <Popupvideo onClosePopup={onClosePopup} />}
			</React.Fragment>
		</AnalyticsProvider>
	);
};

export default CommonPageRenderer;